import React from "react";
import { StaticImage } from "gatsby-plugin-image";

// css
import * as styles from "./Template_1.module.css";

const HealthAndHappinessSec = () => {
  return (
    <div className={`container-fluid ${styles.healthHappiness__contner}`}>
      <div className={styles.healthHappiness__hdngSec}>
        <h3>Health & happiness</h3>
        <p>
          Our belief is that you should be both{" "}
          <span>healthier and happier.</span>
          This means you can expect a few things from our program.
        </p>
      </div>
      <div className={styles.healthHappiness__cardsSec}>
        <div className={styles.healthHappiness__cardsSec__1}>
          <StaticImage
            src="../../../images/LandingPage/icon_8.png"
            alt=""
            className="img-fluid"
            placeholder="blurred"
            quality={90}
            height={70}
            width={70}
          />
          <h3>Improved mood / energy</h3>
          <p>
            Many of our members report an improvement in their overall mood and
            energy.
          </p>
        </div>
        <div className={styles.healthHappiness__cardsSec__1}>
          <StaticImage
            src="../../../images/LandingPage/icon_9.png"
            alt=""
            className="img-fluid"
            placeholder="blurred"
            quality={90}
            height={70}
            width={70}
          />
          <h3>Fits your lifestyle</h3>
          <p>
            Not everyone starts at the same place. We factor in where you are
            today when creating a personalized treatment plan that will suit
            you, for the long-term.
          </p>
        </div>
        <div className={styles.healthHappiness__cardsSec__1}>
          <StaticImage
            src="../../../images/LandingPage/icon_10.png"
            alt=""
            className="img-fluid"
            placeholder="blurred"
            quality={90}
            height={70}
            width={75}
          />
          <h3>Incorporates your preferences</h3>
          <p>
            Throughout the journey, you tell us what you like and dislike. This
            helps us customize things to your preferences. As an example – the
            food we recommend will be healthy, but we believe it should also be
            tasty!
          </p>
        </div>
        <div className={styles.healthHappiness__cardsSec__1}>
          <StaticImage
            src="../../../images/LandingPage/icon_11.png"
            alt=""
            className="img-fluid"
            placeholder="blurred"
            quality={90}
            height={70}
            width={105}
          />
          <h3>Empowers you!</h3>
          <p>
            You will have personalized information at your fingertips,
            empowering you to make the decisions that are best for you.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HealthAndHappinessSec;
