import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { AnchorLink } from "gatsby-plugin-anchor-links";

// css
import * as styles from "./Template_1.module.css";

const Banner = (props) => {
  return (
    <>
      <div className="container-fluid">
        <div className={`row ${styles.bannerSec__row}`}>
          <div
            className={`col-12 col-md-6 col-lg-6 ${styles.bannerSec__img__mob}`}
          >
            <StaticImage
              src="../../../images/LandingPage/LP_banner_2.png"
              alt=""
              className="img-fluid"
              placeholder="blurred"
              quality={90}
            />
          </div>
          <div className={`col-12 col-md-6 col-lg-6 ${styles.bannerSec__hdng}`}>
            <h3>Achieve Diabetes Remission, reclaim your health</h3>
            <AnchorLink
              to="/twin-consultation/#connectUsForm__LP"
              onAnchorLinkClick={() =>
                props.handleMixpanelCTA("Get in touch with Twin", "Top banner")
              }
            >
              <button>Get in touch with Twin</button>
            </AnchorLink>
          </div>
          <div className={`col-12 col-md-6 col-lg-6 ${styles.bannerSec__img}`}>
            <StaticImage
              src="../../../images/LandingPage/LP_banner_2.png"
              alt=""
              className="img-fluid"
              placeholder="blurred"
              quality={90}
            />
            <div className={styles.bannerSec__imgPara}>
              <div className={styles.bannerSec__imgPara__line}></div>
              <p>Mary’s Digital Twin</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
