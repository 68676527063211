import React from "react";
import { StaticImage } from "gatsby-plugin-image";

// css
import * as styles from "./Template_1.module.css";

const TwinContent = () => {
  return (
    <div>
      <div className="container-fluid">
        <div className={`row ${styles.twinContentSec__row}`}>
          <div
            className={`col-12 col-md-5 col-lg-5 ${styles.twinContentSec__imgSec}`}
          >
            <StaticImage
              src="../../../images/LandingPage/banner-2.png"
              alt=""
              className="img-fluid"
              placeholder="blurred"
              quality={90}
            />
          </div>
          <div
            className={`col-12 col-md-6 col-lg-6 ${styles.twinContentSec__cntntSec}`}
          >
            <h3>
              Beating type 2 diabetes is possible with your{" "}
              <span> Whole Body Digital Twin™</span>
            </h3>
            <h5>
              Type 2 diabetes doesn’t have to be something you live with your
              whole life. The Twin Diabetes Program uses the latest technology
              to tackle the cause of type 2. It helps you safely achieve
              diabetes remission and take less meds.
            </h5>
            <div></div>
            <p>
              Your <span>Whole Body Digital Twin™ </span> is your guide to
              better health. Every day, it learns about you and what’s best for
              your health. Your Twin makes recommendations specific to you
              through an easy-to-use app. We give you clear steps you can take
              to beat type 2 diabetes.
            </p>
          </div>
          <div className="col-12 col-md-1 col-lg-1"></div>
        </div>
      </div>
    </div>
  );
};

export default TwinContent;
