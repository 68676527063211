import React from "react";
import Modal from "react-bootstrap/Modal";
import YouTube from "react-youtube";

// services
import { Mixpanel, MixpanelEvents } from "../../../services/Mixpanel";

// styles
import * as styles from "./VideoModal.module.css";
//constants
// const videoLink =
//   "https://player.vimeo.com/video/489364762?autoplay=1&loop=0&autopause=0&muted=0";

const VideoModal = props => {
  const segments = props?.videoLink.split("/") || "";
  const videoCode = segments.pop() || segments.pop(); // Handle potential trailing slash
  // console.log("videoCode:", videoCode);

  const sessionId = props.sessionId + "_" + videoCode;
  const opts = {
    height: "100%",
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      rel: 0,
    },
  };

  const _onReady = event => {
    console.log("started...");
    Mixpanel.track(MixpanelEvents.video_playback_started, {
      page_name: "twin-consultation",
      session_id: sessionId,
      video_name: props.videoName,
    });
  };
  const _onPlay = event => {
    console.log("play/resumed...");
    Mixpanel.track(MixpanelEvents.video_playback_resumed, {
      page_name: "twin-consultation",
      session_id: sessionId,
      video_name: props.videoName,
    });
  };

  const _onPause = event => {
    console.log("paused...");
    Mixpanel.track(MixpanelEvents.video_playback_paused, {
      page_name: "twin-consultation",
      session_id: sessionId,
      video_name: props.videoName,
    });
  };
  const _onEnd = event => {
    console.log("end/completed...");
    Mixpanel.track(MixpanelEvents.video_playback_completed, {
      page_name: "twin-consultation",
      session_id: sessionId,
      video_name: props.videoName,
    });
    props.updateSessionId();
    setTimeout(() => {
      props.onHide();
    }, 300);
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        backdrop="static"
        keyboard={false}
        fullscreen={true}
        contentClassName={styles.newContentVideoModal}
      >
        <div className={styles.newVideoModalClose}>
          <span
            className="icon-cross"
            aria-hidden="true"
            role="presentation"
            onClick={props.onHide}
          ></span>
        </div>
        <YouTube
          style={{ width: "100%", height: "100%" }}
          videoId={videoCode}
          opts={opts}
          onReady={_onReady}
          onPlay={_onPlay}
          onPause={_onPause}
          onEnd={_onEnd}
        />
        ;
        {/* <iframe
          title={"feedback"}
          src={props.videoLink}
          frameborder="0"
          allow="autoplay; fullscreen"
          webkitAllowFullScreen
          mozallowfullscreen
          allowFullScreen
          style={{ width: "100%", height: "100%" }}
        /> */}
      </Modal>
    </>
  );
};

export default VideoModal;
