// extracted by mini-css-extract-plugin
export var bannerSec__hdng = "Template_1-module--bannerSec__hdng--mtOTH";
export var bannerSec__img = "Template_1-module--bannerSec__img--hDOka";
export var bannerSec__imgPara = "Template_1-module--bannerSec__imgPara--sc+b+";
export var bannerSec__imgPara__line = "Template_1-module--bannerSec__imgPara__line--RO3UM";
export var bannerSec__img__mob = "Template_1-module--bannerSec__img__mob--sY5Bu";
export var bannerSec__row = "Template_1-module--bannerSec__row--mTFfI";
export var connectUsForm__inpBox = "Template_1-module--connectUsForm__inpBox--BqkMZ";
export var connect_withus_chk_label = "Template_1-module--connect_withus_chk_label--5fjUD";
export var connect_withus_container = "Template_1-module--connect_withus_container--BOoPI";
export var connect_withus_form = "Template_1-module--connect_withus_form--OCAQO";
export var connect_withus_heading = "Template_1-module--connect_withus_heading--jT6YX";
export var contactUs__terms = "Template_1-module--contactUs__terms--XMzyM";
export var faqSection__contner = "Template_1-module--faqSection__contner--7PxMq";
export var faqSection__hdng = "Template_1-module--faqSection__hdng--Q0oc2";
export var faqSection__lastCard = "Template_1-module--faqSection__lastCard--dBD3E";
export var faqSection__leftCard = "Template_1-module--faqSection__leftCard--qvRKQ";
export var faqSection__rightCard = "Template_1-module--faqSection__rightCard--tcgYh";
export var faqSection__row1 = "Template_1-module--faqSection__row1--gnybP";
export var faqSection__row2 = "Template_1-module--faqSection__row2--hRj5P";
export var faqSection__row3 = "Template_1-module--faqSection__row3--4fdXh";
export var getInTouch__col1 = "Template_1-module--getInTouch__col1--m9jS2";
export var getInTouch__col2 = "Template_1-module--getInTouch__col2--ar1Il";
export var getInTouch__contner = "Template_1-module--getInTouch__contner--Ahcdp";
export var headerSec__button = "Template_1-module--headerSec__button--EkmYz";
export var headerSec__logoImg = "Template_1-module--headerSec__logoImg--E3GDH";
export var headerSec__para = "Template_1-module--headerSec__para--VON06";
export var headerSec__row = "Template_1-module--headerSec__row--DW0gM";
export var healthHappiness__cardsSec = "Template_1-module--healthHappiness__cardsSec--FHjsp";
export var healthHappiness__cardsSec__1 = "Template_1-module--healthHappiness__cardsSec__1--pZflv";
export var healthHappiness__contner = "Template_1-module--healthHappiness__contner--h+vSz";
export var healthHappiness__hdngSec = "Template_1-module--healthHappiness__hdngSec--55pSD";
export var personalSol__hdng = "Template_1-module--personalSol__hdng--5lGZs";
export var personalSol__img = "Template_1-module--personalSol__img--6bpx9";
export var personalSol__imgSec = "Template_1-module--personalSol__imgSec--IIgfN";
export var personalSol__row = "Template_1-module--personalSol__row--rjRuA";
export var personalSol__subHdng = "Template_1-module--personalSol__subHdng--tATrQ";
export var submit__cta__loader = "Template_1-module--submit__cta__loader--qKLe2";
export var submit_cta_btn = "Template_1-module--submit_cta_btn--Ee6Wn";
export var submit_cta_text = "Template_1-module--submit_cta_text--e0Q8A";
export var successfulSection__col1 = "Template_1-module--successfulSection__col1--lgw1G";
export var successfulSection__col1__img = "Template_1-module--successfulSection__col1__img--6arbo";
export var successfulSection__col2 = "Template_1-module--successfulSection__col2--H418L";
export var successfulSection__col3 = "Template_1-module--successfulSection__col3--r9wGa";
export var successfulSection__col3__img = "Template_1-module--successfulSection__col3__img--DUgVp";
export var successfulSection__contner = "Template_1-module--successfulSection__contner--xVDKk";
export var successfulSection__hdng = "Template_1-module--successfulSection__hdng--H8RP-";
export var successfulSection__logoSec = "Template_1-module--successfulSection__logoSec--rrDaS";
export var testimonial__col = "Template_1-module--testimonial__col--u97rR";
export var testimonial__contentSec = "Template_1-module--testimonial__contentSec--X54VX";
export var testimonial__contner = "Template_1-module--testimonial__contner--6zamS";
export var testimonial__hdng = "Template_1-module--testimonial__hdng--iVy0d";
export var testimonial__imgSec = "Template_1-module--testimonial__imgSec--oI5FE";
export var testimonial__line = "Template_1-module--testimonial__line--GrmHT";
export var testimonial__pager = "Template_1-module--testimonial__pager--Ts6eC";
export var testimonial__playIcon = "Template_1-module--testimonial__playIcon--k7aGD";
export var testimonial__subContent = "Template_1-module--testimonial__subContent--JjTvn";
export var toastContainer = "Template_1-module--toastContainer--tE2vM";
export var twinContentSec__cntntSec = "Template_1-module--twinContentSec__cntntSec--V8oev";
export var twinContentSec__imgSec = "Template_1-module--twinContentSec__imgSec--JGGTl";
export var twinContentSec__row = "Template_1-module--twinContentSec__row--EIUSA";
export var twinReverseSec__bottomTxt = "Template_1-module--twinReverseSec__bottomTxt--c8yZ7";
export var twinReverseSec__cardSec = "Template_1-module--twinReverseSec__cardSec--ZceqE";
export var twinReverseSec__col = "Template_1-module--twinReverseSec__col--3Qh3g";
export var twinReverseSec__cont = "Template_1-module--twinReverseSec__cont--kySnX";
export var twinReverseSec__hdng = "Template_1-module--twinReverseSec__hdng--LeffC";
export var twinReverseSec__row = "Template_1-module--twinReverseSec__row--eMUFH";
export var whatTwin__hdng = "Template_1-module--whatTwin__hdng--qmOyk";
export var whatTwin__img = "Template_1-module--whatTwin__img--LdnLl";
export var whatTwin__imgSec = "Template_1-module--whatTwin__imgSec--q2ZHU";
export var whatTwin__subHdng = "Template_1-module--whatTwin__subHdng--DLohN";