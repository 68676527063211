import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { AnchorLink } from "gatsby-plugin-anchor-links";

// css
import * as styles from "./Template_1.module.css";

const Header = props => {
  return (
    <>
      <div className="container-fluid">
        <div className={`row ${styles.headerSec__row}`}>
          <div
            className={`col-6 col-md-2 col-lg-2 ${styles.headerSec__logoImg}`}
          >
            <StaticImage
              src="../../../images/new-logo.png"
              alt=""
              className="img-fluid"
              placeholder="blurred"
              quality={90}
              height={95}
            />
          </div>
          <div className={`col-md-8 col-lg-8 ${styles.headerSec__para}`}>
            <p>Achieve Diabetes Remission, reclaim your health</p>
          </div>
          <div
            className={`col-6 col-md-2 col-lg-2 ${styles.headerSec__button}`}
          >
            <AnchorLink
              to="/twin-consultation/#connectUsForm__LP"
              onAnchorLinkClick={() =>
                props.handleMixpanelCTA("Get in touch with Twin", "Top header")
              }
            >
              <button>Get in touch with Twin</button>
            </AnchorLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
