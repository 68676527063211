import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Loader from "react-loader-spinner";
// import { ToastContainer, toast } from "react-toastify";
// import { Link } from "gatsby";
// import { navigate } from "gatsby";

// services
import { Mixpanel, MixpanelEvents } from "../../../services/Mixpanel";

// css
import * as styles from "./Template_1.module.css";

const ConnectUsForm = props => {
  const [languageValue, setLanguageValue] = useState("");
  const [timeSlotValue, setTimeSlotValue] = useState("");
  const [showLanguageError, setShowLanguageError] = useState("none"); // show error for language selection if left empty
  const [showTimeError, setShowTimeError] = useState("none");

  const [formTouched, setFormTouched] = useState(true);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid, isSubmitted, isDirty },
  } = useForm({ mode: "all" });

  if (isDirty && formTouched) {
    Mixpanel.track(MixpanelEvents.contact_form_started, {
      page_name: "twin-consultation",
      form_is_modal: false,
    });
    setFormTouched(false);
  }

  const handleLanguageChange = e => {
    if (e.target.value) {
      setShowLanguageError("none");
      setLanguageValue(e.target.value);
    } else {
      setShowLanguageError("block");
    }
  };

  const handleTimeSlotChange = e => {
    if (e.target.value) {
      setTimeSlotValue(e.target.value);
      setShowTimeError("none");
    } else {
      setShowTimeError("block");
    }
  };

  const onSubmit = data => {
    Mixpanel.track(MixpanelEvents.contact_form_completed, {
      page_name: "twin-consultation",
      form_is_modal: false,
    });

    const formData = {
      name: data.name,
      phone: data.phone,
      email: data.email,
      city: data.city,
      language: languageValue.toString(),
      timeSlot: timeSlotValue.toString(),
    };
    props.formDataToLsq(formData);

    setLanguageValue("");
    setTimeSlotValue("");
    setShowLanguageError("none");
    setShowTimeError("none");
    reset();
  };

  return (
    <>
      <div className={`container-fluid ${styles.connect_withus_container}`}>
        <div className="row">
          <div
            className={`col-12 text-center ${styles.connect_withus_heading}`}
          >
            <h2>Connect with us</h2>
          </div>
        </div>

        <form
          className={`mt-5 ${styles.connect_withus_form}`}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="row g-3 mb-3">
            <div className={`col-md-6 ${styles.connectUsForm__inpBox}`}>
              {/* <label htmlFor="firstName" className="form-label">
                Legal First name *
              </label> */}
              <input
                type="text"
                placeholder="Name"
                id="name"
                className={
                  errors.name ? "form-control is-invalid" : "form-control"
                }
                {...register("name", {
                  required: "Please enter your name",
                  pattern: {
                    value: /[a-zA-Z][a-zA-Z ]*/, // eslint-disable-line no-useless-escape
                    message: "Please enter your valid name",
                  },
                  maxLength: {
                    value: 50,
                    message: "Name is too long",
                  },
                })}
              />
              {errors.name && (
                <span className="invalid-feedback">{errors.name.message}</span>
              )}
            </div>
            <div className={`col-md-6 ${styles.connectUsForm__inpBox}`}>
              {/* <label className="form-label" htmlFor="phoneNumber">
                Phone number *
              </label> */}
              <input
                type="text"
                placeholder="WhatsApp number"
                id="phoneNumber"
                inputMode="numeric"
                maxLength="10"
                className={`form-control letterSp1 ${
                  errors.phone ? "is-invalid" : ""
                }`}
                {...register("phone", {
                  required: "Please enter your mobile number",
                  pattern: {
                    value: /^\+?([1-9]{1})\)?[-. ]?([0-9]{5})[-. ]?([0-9]{4})$/, // eslint-disable-line no-useless-escape
                    message: "Please enter your valid mobile number",
                  },
                  maxLength: {
                    value: 10,
                    message: "Invalid mobile number",
                  },
                })}
              />
              {errors.phone && (
                <span className="invalid-feedback">{errors.phone.message}</span>
              )}
            </div>
          </div>
          <div className="row g-3 mb-3">
            <div className={`col-md-6 ${styles.connectUsForm__inpBox}`}>
              {/* <label className="form-label" htmlFor="email">
                Email Address *
              </label> */}
              <input
                type="email"
                placeholder="Email"
                id="email"
                className={
                  errors.email ? "form-control is-invalid" : "form-control"
                }
                {...register("email", {
                  required: "Please enter your email",
                  pattern: {
                    value:
                      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i, // eslint-disable-line no-useless-escape
                    message: "Please enter your valid email",
                  },
                })}
              />
              {errors.email && (
                <span className="invalid-feedback">{errors.email.message}</span>
              )}
            </div>
            <div className={`col-md-6 ${styles.connectUsForm__inpBox}`}>
              {/* <label htmlFor="firstName" className="form-label">
                Legal First name *
              </label> */}
              <input
                type="text"
                placeholder="City"
                id="city"
                className={
                  errors.city ? "form-control is-invalid" : "form-control"
                }
                {...register("city", {
                  required: "Please enter your city",
                  pattern: {
                    value: /[a-zA-Z][a-zA-Z ]*/, // eslint-disable-line no-useless-escape
                    message: "Please enter your valid city",
                  },
                  maxLength: {
                    value: 50,
                    message: "Name is too long",
                  },
                })}
              />
              {errors.city && (
                <span className="invalid-feedback">{errors.city.message}</span>
              )}
            </div>
          </div>
          <div className="row g-3 mb-3">
            <div className={`col-md-6 ${styles.connectUsForm__inpBox}`}>
              {/* <label className="form-label" htmlFor="state">
                State *
              </label> */}
              <select
                className="form-select"
                id="language"
                value={languageValue}
                onChange={handleLanguageChange}
              >
                <option value="" disabled selected>
                  Language Preferences
                </option>
                <option value="english">English</option>
                <option value="hindi">Hindi</option>
                <option value="kanada">Kanada</option>
                <option value="telugu">Telugu</option>
                <option value="tamil">Tamil</option>
                <option value="malayalam">Malayalam</option>
              </select>

              {/* To show language error */}
              {!languageValue && (
                <span
                  className="text-danger"
                  style={{
                    fontSize: "0.875em",
                    display: showLanguageError,
                  }}
                >
                  <p style={{ marginTop: "4px" }}>Please select a language</p>
                </span>
              )}
            </div>
            <div className={`col-md-6 ${styles.connectUsForm__inpBox}`}>
              <div>
                {/* <label className="form-label" htmlFor="diagnosis">
                  Please select the clinical diagnosis that best describes you.
                </label> */}
                <select
                  className="form-select"
                  id="diagnosis"
                  value={timeSlotValue}
                  onChange={handleTimeSlotChange}
                >
                  <option value="" disabled selected>
                    Preferred Time of Call
                  </option>
                  <option value="10am-1pm">10AM - 1PM</option>
                  <option value="1pm-5pm">1PM - 5PM</option>
                  <option value="5pm-8pm">5PM - 8PM</option>
                </select>
              </div>
              {/* To show language error */}
              {!timeSlotValue && (
                <span
                  className="text-danger"
                  style={{
                    fontSize: "0.875em",
                    display: showTimeError,
                  }}
                >
                  <p style={{ marginTop: "4px" }}>
                    Please select a preferred time
                  </p>
                </span>
              )}
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-12">
              <div className="form-check mb-3 mt-4">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value="terms"
                  id="t_and_c"
                  {...register("terms", {
                    required:
                      "Please check contacted by Twin Health via Call, SMS, WhatsApp & Email box",
                  })}
                />
                <label
                  className={`form-check-label ${styles.connect_withus_chk_label}`}
                  htmlFor="t_and_c"
                >
                  I agree to be contacted by Twin Health via Call, SMS, WhatsApp
                  & Email
                </label>
              </div>
              {errors.terms && (
                <span className={styles.contactUs__terms}>{errors.terms.message}</span>
              )}
            </div>
          </div>

          <div className="row pt-3">
            <div className="col-md-3 col-lg-3"></div>
            <div className="col-md-6 col-lg-6">
              <span className="errorclass text-center text-danger">
                {!isValid && isSubmitted}
              </span>
              <button
                type="submit"
                className={styles.submit_cta_btn}
                onClick={() => {
                  setShowLanguageError("block");
                  setShowTimeError("block");
                }}
              >
                <span className={props.loading && styles.submit_cta_text}>
                  Submit
                </span>
                <span className={styles.submit__cta__loader}>
                  <Loader
                    type="TailSpin"
                    color="#FFF"
                    height={30}
                    width={30}
                    radius={1}
                    visible={props.loading}
                  />
                </span>
              </button>
            </div>
            <div className="col-md-3 col-lg-3"></div>
          </div>
        </form>
      </div>
    </>
  );
};

export default ConnectUsForm;
