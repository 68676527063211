import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Carousel } from "react-responsive-carousel";

// utils
import { getSessionId } from "../../../utils";

// component
import VideoModal from "../VideoModal/VideoModal";

// icons
import playIcon from "../../../images/LandingPage/play_icon.png";

// css
import * as styles from "./Template_1.module.css";

const Testimonial = () => {
  const [sessionId_1, setSessionId1] = useState(getSessionId());
  const [sessionId_2, setSessionId2] = useState(getSessionId());
  const [sessionId_3, setSessionId3] = useState(getSessionId());

  const [showVideoModal, setShowVideoModal] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [videoName, setVideoName] = useState("");

  const handleVideo = (val, sId, name) => {
    setSessionId(sId);
    setVideoName(name);
    setVideoUrl(val);
    toggleVideo();
  };

  const toggleVideo = () => {
    if (videoUrl !== "") setShowVideoModal(!showVideoModal);
  };

  const updateSessionId = () => {
    if (sessionId === sessionId_1) {
      setSessionId1(getSessionId());
      setSessionId(sessionId_1);
    } else if (sessionId === sessionId_2) {
      setSessionId2(getSessionId());
      setSessionId(sessionId_2);
    } else if (sessionId === sessionId_3) {
      setSessionId3(getSessionId());
      setSessionId(sessionId_3);
    }
  };

  // const onChange = e => {
  //   setCurrentSlide(e + 1);
  // };

  return (
    <div>
      <div className={`container-fluid ${styles.testimonial__contner}`}>
        <div className={styles.testimonial__hdng}>
          <h3>
            Real <span>results</span> from real members
          </h3>
        </div>
        <Carousel
          showStatus={false}
          showThumbs={false}
          showArrows={false}
          infiniteLoop={true}
          interval={5000}
          autoPlay={true}
          // onChange={onChange}
          showIndicators={true}
          stopOnHover={false}
          className="landingPageCarousel"
        >
          <div className={`row`}>
            <div className={`col-12 ${styles.testimonial__col}`}>
              <div
                className={styles.testimonial__imgSec}
                role="presentation"
                onClick={() => {
                  handleVideo(
                    "https://www.youtube.com/embed/572a4TLBScc",
                    sessionId_1,
                    "Alpana - Testimonial Video-1"
                  );
                }}
              >
                <StaticImage
                  src="../../../images/LandingPage/alpana.png"
                  alt=""
                  className="img-fluid"
                  placeholder="blurred"
                  quality={90}
                />
                <img
                  src={playIcon}
                  alt=""
                  className={styles.testimonial__playIcon}
                />
              </div>
              <div className={styles.testimonial__contentSec}>
                <h5>Find Out How Alpana...</h5>
                <div className={styles.testimonial__line}></div>
                <div className={styles.testimonial__subContent}>
                  <StaticImage
                    src="../../../images/LandingPage/icon_1.png"
                    alt=""
                    className="img-fluid"
                    placeholder="blurred"
                    quality={90}
                    height={40}
                  />
                  <p>23 years with diabetes</p>
                </div>
                <div className={styles.testimonial__subContent}>
                  <StaticImage
                    src="../../../images/LandingPage/icon_3.png"
                    alt=""
                    className="img-fluid"
                    placeholder="blurred"
                    quality={90}
                    height={40}
                  />
                  <p>HbA1c: 7.2 → 5.7</p>
                </div>
                <div className={styles.testimonial__subContent}>
                  <StaticImage
                    src="../../../images/LandingPage/icon_4.png"
                    alt=""
                    className="img-fluid"
                    placeholder="blurred"
                    quality={90}
                    height={40}
                  />
                  <p>Got off meds: 3 → 0</p>
                </div>
              </div>
            </div>
          </div>
          <div className={`row`}>
            <div className={`col-12 ${styles.testimonial__col}`}>
              <div
                className={styles.testimonial__imgSec}
                role="presentation"
                onClick={() => {
                  handleVideo(
                    "https://www.youtube.com/embed/1Qdg1OMXLfo",
                    sessionId_2,
                    "Rangarajan - Testimonial Video-2"
                  );
                }}
              >
                <StaticImage
                  src="../../../images/LandingPage/rangarajan.png"
                  alt=""
                  className="img-fluid"
                  placeholder="blurred"
                  quality={90}
                />
                <img
                  src={playIcon}
                  alt=""
                  className={styles.testimonial__playIcon}
                />
              </div>
              <div className={styles.testimonial__contentSec}>
                <h5>Find Out How Rangarajan...</h5>
                <div className={styles.testimonial__line}></div>
                <div className={styles.testimonial__subContent}>
                  <StaticImage
                    src="../../../images/LandingPage/icon_1.png"
                    alt=""
                    className="img-fluid"
                    placeholder="blurred"
                    quality={90}
                    height={40}
                  />
                  <p>4 years with diabetes</p>
                </div>
                <div className={styles.testimonial__subContent}>
                  <StaticImage
                    src="../../../images/LandingPage/icon_3.png"
                    alt=""
                    className="img-fluid"
                    placeholder="blurred"
                    quality={90}
                    height={40}
                  />
                  <p>Sugar mg/dl: 280 → 70</p>
                </div>
                <div className={styles.testimonial__subContent}>
                  <StaticImage
                    src="../../../images/LandingPage/icon_4.png"
                    alt=""
                    className="img-fluid"
                    placeholder="blurred"
                    quality={90}
                    height={40}
                  />
                  <p>Medicines: 2 → 0</p>
                </div>
              </div>
            </div>
          </div>
          <div className={`row`}>
            <div className={`col-12 ${styles.testimonial__col}`}>
              <div
                className={styles.testimonial__imgSec}
                role="presentation"
                onClick={() => {
                  handleVideo(
                    "https://www.youtube.com/embed/gdLkuSqMHCw",
                    sessionId_3,
                    "Usha - Testimonial Video-3"
                  );
                }}
              >
                <StaticImage
                  src="../../../images/LandingPage/usha-2.png"
                  alt=""
                  className="img-fluid"
                  placeholder="blurred"
                  quality={90}
                />
                <img
                  src={playIcon}
                  alt=""
                  className={styles.testimonial__playIcon}
                />
              </div>
              <div className={styles.testimonial__contentSec}>
                <h5>Find Out How Usha...</h5>
                <div className={styles.testimonial__line}></div>
                <div className={styles.testimonial__subContent}>
                  <StaticImage
                    src="../../../images/LandingPage/icon_1.png"
                    alt=""
                    className="img-fluid"
                    placeholder="blurred"
                    quality={90}
                    height={40}
                  />
                  <p>4 years with diabetes</p>
                </div>
                <div className={styles.testimonial__subContent}>
                  <StaticImage
                    src="../../../images/LandingPage/icon_3.png"
                    alt=""
                    className="img-fluid"
                    placeholder="blurred"
                    quality={90}
                    height={40}
                  />
                  <p>Sugar mg/dl: 350 → ~120</p>
                </div>
                <div className={styles.testimonial__subContent}>
                  <StaticImage
                    src="../../../images/LandingPage/icon_4.png"
                    alt=""
                    className="img-fluid"
                    placeholder="blurred"
                    quality={90}
                    height={40}
                  />
                  <p>Medicine: ~3 → 0</p>
                </div>
              </div>
            </div>
          </div>
        </Carousel>
        {/* <div className={styles.testimonial__pager}>
          <span
            role="presentation"
            className={`icon-back`}
            // onClick={prevSlide}
          />
          <p>{currentSlide}/3</p>
          <span
            role="presentation"
            className={`icon-arrow-forward`}
            // onClick={nextSlide}
          />
        </div> */}
      </div>
      <VideoModal
        show={showVideoModal}
        onHide={toggleVideo}
        videoLink={videoUrl}
        sessionId={sessionId}
        videoName={videoName}
        updateSessionId={updateSessionId}
      />
    </div>
  );
};

export default Testimonial;
