import mixpanel from "mixpanel-browser";

// config
import config from "../../config";

mixpanel.init(config.MIXPANEL_V2);

const DX_APP = "DX App";

// let env_check = process.env.NODE_ENV === "production";
let env_check = true;

let actions = {
  identify: id => {
    if (env_check) mixpanel.identify(id);
  },
  alias: id => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (env_check)
      mixpanel.track(name, {
        app_name: DX_APP,
        ...props,
      });
  },
  people: {
    set: props => {
      if (env_check)
        mixpanel.people.set({
          app_name: DX_APP,
          ...props,
        });
    },
  },
};

export const Mixpanel = actions;

// mixpanel events, tags=conversion
export const MixpanelEvents = {
  page_viewed: "Page Viewed",
  page_depth_viewed: "Page Depth Viewed",
  contact_form_started: "Contact Form Started",
  contact_form_completed: "Contact Form Completed",
  contact_button_clicked: "Contact Button Clicked",
  video_playback_started: "Video Playback Started",
  video_playback_completed: "Video Playback Completed",
  video_playback_paused: "Video Playback Paused",
  video_playback_resumed: "Video Playback Resumed",
};
