import React from "react";
import { StaticImage } from "gatsby-plugin-image";

// css
import * as styles from "./Template_1.module.css";

const TwinReverse = () => {
  return (
    <div>
      <div className={`container-fluid ${styles.twinReverseSec__cont}`}>
        <div className={`text-center ${styles.twinReverseSec__hdng}`}>
          <h3>
            Type 2 diabetes <span>remission</span> is possible with Twin
          </h3>
        </div>
        <div className={`row ${styles.twinReverseSec__row}`}>
          <div className="col-12">
            <div className={styles.twinReverseSec__col}>
              <div className={styles.twinReverseSec__cardSec}>
                <StaticImage
                  src="../../../images/LandingPage/icon_1.png"
                  alt=""
                  className="img-fluid"
                  placeholder="blurred"
                  quality={90}
                  height={60}
                />
                <h3>Normalize blood sugar</h3>
                <h4>90%</h4>
                <p>
                  Achieved type 2 diabetes reversal with an average HbA1c
                  reduction of 3.1
                </p>
              </div>
              <div className={`ms-3 ${styles.twinReverseSec__cardSec}`}>
                <StaticImage
                  src="../../../images/LandingPage/icon_2.png"
                  alt=""
                  className="img-fluid"
                  placeholder="blurred"
                  quality={90}
                  height={60}
                />
                <h3>Improve mood & energy</h3>
                <h4>95%</h4>
                <p>Report feeling happier</p>
              </div>
              <div className={`ms-3 ${styles.twinReverseSec__cardSec}`}>
                <StaticImage
                  src="../../../images/LandingPage/icon_3.png"
                  alt=""
                  className="img-fluid"
                  placeholder="blurred"
                  quality={90}
                  height={60}
                />
                <h3>Reduce medications</h3>
                <h4>92%</h4>
                <p>
                  Eliminated and avoided all diabetes medications, including
                  insulin
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.twinReverseSec__bottomTxt}>
          <p>
            Our clinical research team is conducting the world’s first
            randomized controlled trial for reversing chronic metabolic disease
            using digital twin technology. Trial data published in the American
            Diabetes Association’s Diabetes journal shows first-of-their-kind
            health outcomes.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TwinReverse;
