import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";

// utils
import { getSessionId } from "../../../utils";

// css
import * as styles from "./Template_1.module.css";

// component
import VideoModal from "../VideoModal/VideoModal";

const PersonalizedSolution = props => {
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [sessionId, setSessionId] = useState(getSessionId());

  // let videoUrl = "https://player.vimeo.com/video/819442738?h=651b90efcf";

  let videoUrl = "https://www.youtube.com/embed/arRgs0n85Dg";

  const toggleVideo = () => {
    if (videoUrl !== "") setShowVideoModal(!showVideoModal);
  };

  const updateSessionId = () => {
    setSessionId(getSessionId());
  };

  return (
    <>
      <div className="container-fluid">
        <div className={`row ${styles.personalSol__row}`}>
          {/* Heading */}
          <div className={`text-center ${styles.personalSol__hdng}`}>
            <h3>
              A solution <span>personalized</span>, just for you
            </h3>
          </div>
          {/* Video-Section */}
          <div className="col-md-1 col-lg-1"></div>
          <div
            className={`col-12 col-md-10 col-lg-10 ${styles.personalSol__imgSec}`}
            role="presentation"
            onClick={() => {
              toggleVideo();
            }}
          >
            <div className={styles.personalSol__img}>
              <StaticImage
                src="../../../images/LandingPage/banner_3.png"
                alt=""
                className="img-fluid"
                placeholder="blurred"
                quality={90}
              />
            </div>

            {/* Sub-Heading */}
            <div className={`text-center ${styles.personalSol__subHdng}`}>
              <p>
                Find out how having your own{" "}
                <span>Whole Body Digital Twin™</span>, can help you achieve
                diabetes remission.
              </p>
            </div>
          </div>
          <div className="col-md-1 col-lg-1"></div>
        </div>
      </div>
      <VideoModal
        show={showVideoModal}
        onHide={toggleVideo}
        videoLink={videoUrl}
        sessionId={sessionId}
        videoName={"Personalized Solution"}
        updateSessionId={updateSessionId}
      />
    </>
  );
};

export default PersonalizedSolution;
