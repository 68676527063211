import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { AnchorLink } from "gatsby-plugin-anchor-links";

// css
import * as styles from "./Template_1.module.css";

const GetInTouch = props => {
  return (
    <div>
      <div className={`container-fluid ${styles.getInTouch__contner}`}>
        <div className="row">
          <div
            className={`col-12 col-md-5 col-lg-5 ${styles.getInTouch__col1}`}
          >
            <StaticImage
              src="../../../images/LandingPage/LP_banner_2.png"
              alt=""
              className="img-fluid"
              placeholder="blurred"
              quality={90}
            />
          </div>
          <div
            className={`col-12 col-md-7 col-md-7 ${styles.getInTouch__col2}`}
          >
            <p>
              Twin is a <span>fully-covered</span> medical benefit for employees
              and adult dependents 18 and older with type 2 diabetes enrolled in
              Medline’s health plan.
            </p>
            <AnchorLink
              to="/twin-consultation/#connectUsForm__LP"
              onAnchorLinkClick={() =>
                props.handleMixpanelCTA(
                  "Get in touch with Twin",
                  "Bottom Testimonial"
                )
              }
            >
              <button>Get in touch with Twin</button>
            </AnchorLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;
